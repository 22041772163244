<!--采购汇总-->
<template>
    <div class="InOutSummary" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-select v-model="form.deptType" @change="handleSelectType">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="item in meta.types"
                                    :label="item.name"
                                    :value="item.type"
                                    :key="item.type"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="关键字" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否结账">
                            <el-select placeholder="是否结账" v-model="form.settleStatus">
                                <el-option value="" label="请选择" />
                                <el-option value="0" label="未结账" />
                                <el-option value="1" label="已结账" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.report.purchase.inOutSummary.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.purchase.inOutSummary.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="480"
                :row-style="rowStyle"
            >
                <el-table-column label="序号" width="50" type="index" fixed="left" />
                <el-table-column
                    label="机构名称"
                    width="100"
                    prop="deptName"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column
                    label="商品名称"
                    width="140"
                    prop="sku.name"
                    key="sku.name"
                    fixed="left"
                    v-if="showColumn('sku.name')"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.sku">{{ scope.row.sku.name || '' }}</span>
                        <span v-else-if="!scope.row.status">小结</span>
                        <span v-else>合计</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品条码"
                    width="110"
                    prop="sku.bars"
                    key="sku.bars"
                    v-if="showColumn('sku.bars')"
                >
                    <template slot-scope="scope">
                        <div v-if="scope.row.sku">
                            <span v-for="bar in scope.row.sku.bars || []" :key="bar">{{ bar }}<br /></span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="80" prop="sku.specs" v-if="showColumn('sku.specs')" />
                <el-table-column label="类目" width="120" prop="sku.category" v-if="showColumn('sku.category')" />
                <el-table-column label="单位" width="100" prop="sku.unit" v-if="showColumn('sku.unit')" />
                <el-table-column label="品牌" width="100" prop="sku.brand" v-if="showColumn('sku.brand')" />
                <el-table-column
                    label="是否结账"
                    width="80"
                    prop="settleStatus"
                    key="settleStatus"
                    v-if="showColumn('settleStatus')"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.sku">{{ scope.row.settleStatus ? '已结账' : '未结账' }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="采购数量汇总"
                    width="120"
                    prop="inCount"
                    :formatter="mCount"
                    v-if="showColumn('inCount')"
                />
                <el-table-column
                    label="采购成本金额汇总"
                    width="120"
                    prop="inCostMoney"
                    :formatter="mFour"
                    v-if="showColumn('inCostMoney')"
                />
                <el-table-column
                    label="采购赠品数量汇总"
                    width="120"
                    prop="inGiftCount"
                    :formatter="mCount"
                    v-if="showColumn('inGiftCount')"
                />
                <el-table-column
                    label="采购赠品成本金额汇总"
                    width="160"
                    prop="inGiftCostMoney"
                    :formatter="mFour"
                    v-if="showColumn('inGiftCostMoney')"
                />
                <el-table-column
                    label="采购金额汇总"
                    width="120"
                    prop="inMoney"
                    :formatter="mFour"
                    v-if="showColumn('inMoney')"
                />
                <el-table-column
                    label="退货数量汇总"
                    width="120"
                    prop="outCount"
                    :formatter="mCount"
                    v-if="showColumn('outCount')"
                />
                <el-table-column
                    label="退货成本金额汇总"
                    width="120"
                    prop="outCostMoney"
                    :formatter="mFour"
                    v-if="showColumn('outCostMoney')"
                />
                <el-table-column
                    label="退货赠品数量汇总"
                    width="120"
                    prop="outGiftCount"
                    :formatter="mCount"
                    v-if="showColumn('outGiftCount')"
                />
                <el-table-column
                    label="退货赠品成本金额汇总"
                    width="160"
                    prop="outGiftCostMoney"
                    :formatter="mFour"
                    v-if="showColumn('outGiftCostMoney')"
                />
                <el-table-column
                    label="退货金额汇总"
                    width="120"
                    prop="outMoney"
                    :formatter="mFour"
                    v-if="showColumn('outMoney')"
                />
                <el-table-column
                    label="实际采购数量汇总"
                    width="120"
                    prop="actualCount"
                    :formatter="mCount"
                    v-if="showColumn('actualCount')"
                />
                <el-table-column
                    label="实际采购成本金额汇总"
                    width="160"
                    prop="actualCost"
                    :formatter="mFour"
                    v-if="showColumn('actualCost')"
                />
                <el-table-column
                    label="实际采购金额汇总"
                    width="120"
                    prop="actualMoney"
                    :formatter="mFour"
                    v-if="showColumn('actualMoney')"
                />
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfStartDate from '../../../components/EfStartDate';
import EfEndDate from '../../../components/EfEndDate';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'PurchaseInOutSummary',
    components: { EfEndDate, EfStartDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            emptyZero: true,
            form: {
                emptyZero: true,
                deptType: '',
                deptCode: '',
                startTime: '',
                endTime: '',
                search: '',
                settleStatus: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/report/purchaseInvoicingSummary',
            },
            meta: {
                types: [],
                depts: [],
            },
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.DeptTrees(_this, (rst) => {
            _this.meta.types = rst;
            _this.form.deptType = _this.meta.types[_this.meta.types.length - 1].type;
            this.handleSelectType(_this.form.deptType);
        });
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleExport() {
            UrlUtils.Export(this, '采购汇总表', '/report/purchaseInvoicingSummary/export', this.form);
        },
        handleSelectType(deptType) {
            this.form.deptCode = '';
            this.meta.depts = this.meta.types.find((t) => t.type == deptType).children || [];
            this.form.deptCode = this.meta.depts[0].code;
            this.handleQuery();
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        rowStyle(row) {
            const style = {};
            if (typeof row.row.sku == 'undefined' && !row.row.status) {
                Object.assign(style, {
                    background: '#f9f9f9',
                });
            }
            if (typeof row.row.status != 'undefined') {
                Object.assign(style, {
                    fontWeight: 'Bolder',
                });
                if (row.row.status) {
                    Object.assign(style, {
                        fontSize: '14px',
                    });
                }
            }
            return style;
        },
        mFour: Util.mFour,
        mCount: Util.mCount,
    },
};
</script>

<style scoped>
.InOutSummary .el-form-item {
    margin-bottom: 0;
}
</style>
